body {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}

nav {
    font-family: 'Roboto', sans-serif;
    border-bottom-color: red;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    font-size: 15px;
    font-style: italic;
}

.fragment {
    margin-top: 80px;
    padding: 20px;
    font-size: 14px;
    margin-bottom: 100px;
}

.fontRoboto {
    font-family: 'Roboto', sans-serif;
    padding: 20px;
    font-size: 14px;
    margin-bottom: 100px;
}

tr {
    cursor: pointer
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup, .dropdown {
    position: relative;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid;
    content: "";
}

.validatorMessage {
    color: red;
}

.cards {
    padding-top: 50px;
    alignment: left;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.imageClick {
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.imageClick:hover {
    border-color: red;
    text-decoration: none;
}

html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 60px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background-color: #f8f9fa;
    text-align: center;
    font-size: 10px;
    padding-top: 45px;
    color: grey;
    border-top-color: red;
    border-top-width: 1px;
    border-top-style: solid;
}

.form-label{
    margin-top: 10px;
    margin-bottom: 0px !important;
}
.form-control{
    magrin-top: 0px
}

.clickableFont {
    cursor: pointer;
}

.dropdown-toggle-without-arrow::after {
    display: none !important;
}

/*stylizacja tabs*/
.nav.nav-tabs .nav-link.active{
    border-bottom: 1px solid grey !important;
    color: grey !important;
    background-color: #262626 !important;
    color: white !important;
}
.nav-link{
    color: grey !important;
}
/*--*/
.react-bootstrap-table table {
    table-layout: auto;
}

.leaflet-container {
    height: 800px;
    width: 100%;
}