.nav-link.active {
    color: black !important;
    background-color: lightgrey !important;
}

.nav-tabs {
    background-color: white;
    color: black !important;
    margin: 3px;
}

.nav-item {
    color: black !important;

}